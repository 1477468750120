<template>
    <ml-row v-for="(row, index) in value" v-bind:data="row" v-bind:key="index" v-bind:properties="list_properties"
        v-bind:id="index" @row_event_click="row_event"></ml-row>
</template>

<script>
import Card from "./Card.vue"
export default {
    components: {
        'ml-row': Card
    },
    props: ['properties', 'rows'],
    emits: ['row_event'], // 添加這行
    data() {
        return {
            list_properties: this.properties,
            value: this.rows
        };
    },
    methods: {
        row_event: function (event, params) {
            this.$emit('row_event', event, params);
        }
    }
};
</script>

<style scoped></style>