<template>
  <div class="card card-default">
    <div class="card-header bg-info">
      <h3 class="card-title">{{ widget_meta.title }}</h3>

      <div class="card-tools">
        <!--
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
        -->
      </div>
    </div>

    <div class="card-body tools">
      <div class="breadcrumb float-sm-left ml-2 back" @click="back" v-show="this.widget.history.length > 1">
        <i class="fas fa-arrow-left"></i>&nbsp;&nbsp;回上一層
      </div>
      <ol class="breadcrumb float-sm-right mr-2">
        <template v-for="(item, key) in history" v-bind:key="key">
          <li class="breadcrumb-item link" v-if="key == 0" @click="back_to(key)"><i class="fas fa-home"></i> Home</li>
          <li class="breadcrumb-item" v-else-if="key == history.length - 1">{{ item.title }}</li>
          <li class="breadcrumb-item link" v-else @click="back_to(key)">{{ item.title }}</li>
        </template>
      </ol>
    </div>
    <div class="card-body tools">
      <div class="btn-group">
        <button type="button" class="btn btn-default bg-green" title="新增" @click="add"
          v-show="widget_meta.widget.properties.permissions['add'] != undefined && widget_meta.widget.properties.permissions['add'] == 'Y'">
          <i class="fas fa-plus"></i></button>
        <button type="button" class="btn btn-default bg-red" title="刪除"
          v-show="widget_meta.widget.properties.permissions['delete'] != undefined && widget_meta.widget.properties.permissions['delete'] == 'Y'">
          <i class="fas fa-trash-alt"></i></button>
        <button type="button" class="btn btn-default" title="匯出"
          v-show="widget_meta.widget.properties.permissions['export'] != undefined && widget_meta.widget.properties.permissions['export'] == 'Y'">
          <i class="fas fa-cloud-download-alt"></i></button>
        <button type="button" class="btn btn-default" title="匯入"
          v-show="widget_meta.widget.properties.permissions['import'] != undefined && widget_meta.widget.properties.permissions['import'] == 'Y'">
          <i class="fas fa-cloud-upload-alt"></i></button>
        <button type="button" class="btn btn-default bg-purple" title="排序" @click="sort"
          v-show="widget_meta.widget.properties.permissions['sort'] != undefined && widget_meta.widget.properties.permissions['sort'] == 'Y'">
          <i class="fas fa-sort-amount-down"></i></button>
        <button type="button" class="btn btn-default" title="搜尋" @click="open_search('open')"
          v-show="widget_meta.widget.properties.permissions['search'] != undefined && widget_meta.widget.properties.permissions['search'] == 'Y'">
          <i class="fas fa-search"></i></button>

      </div>
    </div>
    <div class="card-body search"
      v-show="widget_meta.widget.properties.permissions['search'] != undefined && widget_meta.widget.properties.permissions['search'] == 'Y' && open_search_area">
      <div class="row">
        <div v-for="field_meta in widget_meta.widget.properties.search_fields" :key="field_meta.variable" :class="[
          'form-group',
          'col-' + field_meta.component.properties.col_width.mobile,
          'col-sm-' + field_meta.component.properties.col_width.pad,
          'col-md-' + field_meta.component.properties.col_width.pad,
          'col-lg-' + field_meta.component.properties.col_width.desktop
        ]">
          <div v-show="field_meta.component.properties.display == 'Y'">
            <component v-bind:is="field_meta._component" v-bind:key="field_meta.variable" v-bind:field="field_meta"
              v-bind:value="''" v-bind:message="''" @input="search_input_event(field_meta, $event)"></component>
          </div>
        </div>
      </div>
      <div class="row float-right">
        <button type="button" @click="search" class="btn bg-maroon">搜尋</button>
        <button type="button" @click="cancel_search" class="btn bg-gray">取消搜尋</button>
      </div>
    </div>
    <div class="card-body">
      <component :is="computedComponentName" v-bind:properties="widget_meta.widget.properties" v-bind:rows="this.value"
        @row_event="row_event" :key="change_counter"></component>
    </div>
    <!-- /.card-body -->
    <div class="card-footer text-center">
      <nav aria-label="Page navigation" v-show="pages > 1">

        <ul class="pagination justify-content-center">
          <li class="page-item" v-show="current_page > 1">
            <a class="page-link" href="javascript:void(0);" @click="page_to(current_page - 1)">&lt;</a>
          </li>
          <template v-for="page_num in pages" v-bind:key="page_num">
            <template v-if="page_num == current_page">
              <li class="page-item active"><a class="page-link" href="javascript:void(0);">{{ page_num }}</a></li>
            </template>
            <template v-else-if="page_num != current_page">
              <li class="page-item"><a class="page-link" href="javascript:void(0);" @click="page_to(page_num)">{{ page_num
              }}</a></li>
            </template>
          </template>
          <li class="page-item" v-show="current_page < pages">
            <a class="page-link" href="javascript:void(0);" @click="page_to(current_page + 1)">&gt;</a>
          </li>
        </ul>
        <div class="page-total">共{{ pages }}頁 :: </div>
        <div class="page-goto">
          前往<input type="number" class="form-control" v-model="current_page" @change="direct_page_to" />頁
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import Table from "./Table.vue"
import Grid from "./Grid.vue"
export default {
  components: {
    'ml-table': Table,
    'ml-grid': Grid
  },
  props: ['widget'],
  data() {

    // 取得 widget 的代碼
    let code = this.widget.widget;
    // 取得 widget 的參數
    let params = this.widget.params;
    // 取得 widget 的元件資訊
    let current_widget_meta = this.$microlink.widgets[code];
    // 資料初始化
    let rows = {};
    // 總頁數
    let pages = 0;
    // 目前頁數
    let page = 1;
    // 參數的網址字串
    let parameters = '';
    // 搜尋參數
    let search_params = {};

    if (current_widget_meta.widget.properties.search_fields.length > 0) {
      let fields = current_widget_meta.widget.properties.search_fields;


      for (let key in fields) {

        // 將欄位轉換成實體的元件
        current_widget_meta.widget.properties.search_fields[key]._component =
          this.$microlink.components[fields[key].component.type].component;

        // 確認登入者是否擁有操作此欄位的權限
        current_widget_meta.widget.properties.search_fields[key].component.properties.display = 'Y';
        let check_result = this.check_field_permission(fields[key]);
        if (check_result == 'hide') {
          current_widget_meta.widget.properties.search_fields[key].component.properties.display = 'N';
        }

        search_params[fields[key].variable] = { 'value': '', 'operator': fields[key].sql };
      }
    }
    

    // 清單組件，必須要有 list_data 來源
    if (current_widget_meta.widget.properties.events.list_data == undefined ||
      current_widget_meta.widget.properties.events.list_data == '') {
      console.log('尚未指定 list_data 來源');
    }
    else {

      if (params == undefined) {
        params = {};
      }

      if (current_widget_meta.widget.properties.items_per_page != undefined) {
        params['_c_'] = current_widget_meta.widget.properties.items_per_page;
      }

      if (current_widget_meta.widget.properties.sort_rule_fields != undefined) {
        for (let i = 0; i < current_widget_meta.widget.properties.sort_rule_fields.length; i++) {
          let variable = current_widget_meta.widget.properties.sort_rule_fields[i].field_variable;
          let rule = current_widget_meta.widget.properties.sort_rule_fields[i].rule;
          params['_s_[' + variable + ']'] = rule;
        }
      }

      // 如果有「資料領域」的設定，則加入到參數中
      if (current_widget_meta.widget.properties.datazone_setting != undefined &&
        current_widget_meta.widget.properties.datazone_setting.datazone != undefined &&
        current_widget_meta.widget.properties.datazone_setting.datazone != '') {
        params['dz'] = current_widget_meta.widget.properties.datazone_setting.datazone;
      }

      // 如果有麵包屑的標題
      let breadcrumb_title = '';
      if (params.breadcrumb_title != undefined) {
        breadcrumb_title = params.breadcrumb_title;
      }

      delete params.breadcrumb_title;

      this.$emit('widgetEvent', { 'event': 'save', 'type': 'list', 'title': breadcrumb_title, 'code': code, 'params': params });

      parameters = this.params_to_string(params);


      // 組合 API 網址
      let api_url = this.$microlink.base_api_url + current_widget_meta.widget.properties.events.list_data + parameters;
      if(params._sr_ != undefined){
        api_url = this.$microlink.base_api_url + current_widget_meta.widget.properties.events.search_data + parameters;
      }
      
      // 取得資料
      let response = this.$microlink.api(api_url);

      // 資料
      rows = response.data;
      // 計算總頁數
      pages = response.total_pages;
      // 目前頁數
      page = (response.current_page == undefined) ? 1 : parseInt(response.current_page);

    }

    return {
      widget_meta: current_widget_meta,
      value: rows,
      params: params,
      history: this.widget.history,
      pages: pages,
      current_page: page,
      change_counter: 1,
      open_search_area: false,
      search_params: search_params,
    }
  },
  computed: {
    computedComponentName() {
      if (window.innerWidth <= 768) {
        return 'ml-grid';
      } else {
        return 'ml-table';
      }
    },
  },
  methods: {
    // 將 params 物件轉換成網址字串
    // 如果面沒有參數，則不加入 & 字元
    params_to_string: function (params) {
      let parameters = '';
      for (let pkey in params) {
        if (parameters != '') {
          parameters += '&';
        }
        parameters += `${pkey}=${params[pkey]}`;
      }

      if (parameters != '') {
        parameters = '?' + parameters;
      }

      return parameters;
    },

    list_data: function () {
      let parameters = this.params_to_string(this.params);
      var api_url = this.$microlink.base_api_url + this.widget_meta.widget.properties.events.list_data + parameters;
      // 取得資料
      let response = this.$microlink.api(api_url);

      // 資料
      this.value = response.data;
      // 計算總頁數
      this.pages = response.total_pages;
      // 目前頁數
      this.current_page = (response.current_page == undefined) ? 1 : parseInt(response.current_page);

      this.change_counter++;

    },
    /**
     * 新增資料
     */
    add: function () {
      // 取得 widget 的代碼
      let code = this.widget.widget;
      this.$emit('widgetEvent', { 'event': 'switch', 'type': 'form', 'code': code, 'params': this.params });
    },
    /**
     * 排序資料
     */
    sort: function () {
      let code = this.widget.widget;
      this.$emit('widgetEvent', { 'event': 'switch', 'type': 'sort', 'code': code, 'params': this.params });
    },
    /**
     * 開啟搜尋資料區域
     */
    open_search: function (event) {
      if (event == 'open') {
        this.open_search_area = true;
      }
    },

    /**
     * 取消搜尋並關閉搜尋資料區域
     */
    cancel_search: function () {
      this.open_search_area = false;
    },
    /**
     * 搜尋資料
     */
    search: function () {
      if(this.search_params.length == 0){
        return;
      }
      let search_json_string = JSON.stringify(this.search_params);
      
      this.params['_sr_'] = this.$microlink.base64_encode(search_json_string);

      let parameters = this.params_to_string(this.params);
      var api_url = this.$microlink.base_api_url + this.widget_meta.widget.properties.events.search_data + parameters;
      // 取得資料
      let response = this.$microlink.api(api_url);

      // 資料
      this.value = response.data;
      // 計算總頁數
      this.pages = response.total_pages;
      // 目前頁數
      this.current_page = (response.current_page == undefined) ? 1 : parseInt(response.current_page);

      this.change_counter++;

      let code = this.widget.widget;

      this.$emit('widgetEvent', { 'event': 'save', 'type': 'list', 'title': '搜尋', 'code': code, 'params': this.params });
    },

    back: function () {
      this.$emit('widgetEvent', { 'event': 'back', 'step': 1 });
    },
    row_event: function (event, params) {

      // 取得 widget 的代碼
      let code = this.widget.widget;

      // 編輯
      if (event == 'edit_row') {
        // 合併 params 及 this.params
        params = Object.assign(params, this.params);
        this.$emit('widgetEvent', { 'event': 'switch', 'type': 'form', 'code': code, 'params': params });
      }
      // 自訂按鈕
      else if (event == 'extend_row') {
        code = params.widget_code;
        delete params.widget_code;
        this.$emit('widgetEvent', { 'event': 'switch', 'code': code, 'params': params });
      }
      // 刪除
      else if (event == 'delete_row') {

        let $this = this;

        this.$microlink.confirm('是否刪除項目', '刪除後就無法再回復囉！', function (response) {
          // 確定刪除
          if (response.isConfirmed) {
            params = Object.assign(params, $this.params);
            let parameters = $this.params_to_string(params);
            let api_url = $this.$microlink.base_api_url + $this.widget_meta.widget.properties.events.list_delete + parameters;
            $this.$microlink.api_get(api_url, true, function () {
              $this.list_data();
              $this.$microlink.alert('刪除成功', '', 'success');
              // 強制重新繪制畫面，否則會出現一種現象
              // 資料已從後端載下並更新了，但表單上的資料仍會是上一次載入的資料
              $this.$forceUpdate();

            });
          }
        });
      }
    },
    /**
     * 從麵包屑導覽列中，切換到指定的 widget
     */
    back_to: function (history_key) {
      let back_step = (this.widget.history.length - history_key - 1);
      this.$emit('widgetEvent', { 'event': 'back', 'step': back_step });
    },

    /**
     * 切換到指定的頁數
     * @param {*} page_num 
     */
    page_to: function (page_num) {

      if (page_num < 1) {
        page_num = 1;
      }
      else if (page_num > this.pages) {
        page_num = this.pages;
      }

      this.params['_p_'] = page_num;
      this.list_data();
    },

    direct_page_to: function () {
      if (this.current_page < 1) {
        this.current_page = 1;
      }
      else if (this.current_page > this.pages) {
        this.current_page = this.pages;
      }

      this.page_to(this.current_page);
    },

    search_input_event: function (meta, $event) {
      let value = ($event.temp == undefined) ? $event.target.value : $event.temp;
      let variable = meta.variable;
      this.search_params[variable].value = value;
    },

    /**
     * 確認是否有權限使用這個元件
     */
    check_field_permission: function (field) {
      let permissions = field.component.properties.permissions;
      let user_permissions = (this.$microlink.userinfo != undefined) ? this.$microlink.userinfo.permission : [];

      if (permissions == '' || permissions == undefined || permissions.length == 0) {
        return 'show';
      }

      // 如果使用者權限中，有包含本欄位的權限，則開始判斷是否有權限
      for (let i = 0; i < permissions.length; i++) {
        if (user_permissions.includes(permissions[i].permission)) {
          // 有權限
          if (permissions[i].action == 'Show') {
            return 'show';
          }
          else {
            return 'read-only';
          }
        }
      }

      return 'hide';
    }
  }
};
</script>

<style scoped>
div.card-footer button {
  margin: 0px 5px;
}

div.search button {
  margin: 0px 3px;
}

div.tools {
  padding: 10px 0px 0px 1.25rem;
  border: none;
}

.operate {
  width: auto;
}

.page-total,
.page-goto {
  display: inline;
}

.pagination {
  display: inline-flex !important;
  margin: 0px 20px;
}

.pagination .disabled {
  display: none;
}

.page-goto input[type="number"] {
  width: 70px !important;
  display: inline;
  margin: 0px 3px;
}

.breadcrumb {
  background-color: white;
}

.breadcrumb-item.link {
  /* 顯示超連結的顏色 */
  color: #007bff;
  cursor: pointer;
}

/* 將麵包屑間隔的符號由 / 改為 > */
.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}

.breadcrumb.back {
  line-height: 17px;
  padding-left: 2px;
  cursor: pointer;
}

.page-goto input[type="number"] {
  text-align: center;
}
</style>