<template>
    <tr v-bind:id="this.id">
        <td><input type="checkbox" /></td>
        <td>
            <button type="button" class="btn btn-default btn-sm bg-green" v-show="false"><i
                    class="fas fa-list-ul"></i></button>
            <button type="button" @click="edit_row" class="btn btn-default btn-sm bg-blue"
                v-show="permissions['fix'] != undefined && permissions['fix'] == 'Y'"><i
                    class="fas fa-edit"></i></button>
            <button type="button" @click="delete_row" class="btn btn-default btn-sm bg-red"
                v-show="permissions['delete'] != undefined && permissions['delete'] == 'Y'"><i
                    class="fas fa-trash-alt"></i></button>

            <template v-if="this.buttons_list_type == 'normal'">

                <template v-for="button in this.buttons" v-bind:key="button.label">
                    <button type="button" @click="extend_row(button)" class="btn btn-default btn-sm"
                        v-bind:class="['bg-' + button.color]">
                        <i v-bind:class="button.icon"></i> {{ button.label }}</button>
                </template>

            </template>
            <template v-else>

                <template v-if="this.buttons.length > 0">
                    <div class="btn-group">
                        <button type="button" class="btn btn-default dropdown-toggle dropdown-icon btn-sm"
                            data-toggle="dropdown">
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <div class="dropdown-menu" role="menu">
                            <template v-for="button in this.buttons" v-bind:key="button.label">
                                <a class="dropdown-item" href="javascript:void(0);" @click="extend_row(button)"> {{
                                    button.label }}</a>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </td>
        <template v-for="field in this.fields" v-bind:key="field.id">
            <td>
                <component v-bind:is="field._component" v-bind:key="field.variable" v-bind:field="field"
                    v-bind:value="this.data[field.variable]" v-bind:message="message[field.variable]"
                    @input="input_event(field, $event)"></component>
            </td>
        </template>
    </tr>
</template>


<script>
export default {
    props: ['id', 'data', 'properties'],
    emits: ['row_event_click', 'button_event', 'extend_row'],
    data() {
        // 取得本地欄位設定
        let localFields = this.properties.list_fields;
        // 初始化訊息物件
        let message = {};

        // 將欄位轉換成實體的元件
        for (let key in localFields) {
            // 取得對應的元件
            localFields[key]._component =
                this.$microlink.components[localFields[key].component.type].component;
            // 初始化訊息物件中的對應欄位
            message[localFields[key].variable] = '';
        }

        // 回傳元件的資料
        return {
            // 權限設定
            permissions: this.properties.permissions,
            // 欄位設定
            fields: localFields,
            // 訊息物件
            message: message,
            // 初始值
            value: this.data,
            // 按鈕設定
            buttons: this.properties.button_fields || [],
            // 按鈕列表類型
            buttons_list_type: this.properties.buttons_list_type || 'normal',
            // 小工具屬性
            widget_properties: this.properties
        };
    },
    methods: {

        /**
         * 編輯列
         * 
         * 說明：觸發編輯列的事件
         *
         * 參數：無
         * 
         * 回傳：無
         *
         * 流程：
         * 1. 觸發 'row_event_click' 事件，並傳遞 'edit_row' 和列的 ID
         */
        edit_row: function () {
            // 觸發 'row_event_click' 事件，並傳遞 'edit_row' 和列的 ID
            this.$emit('row_event_click', 'edit_row', { 'id': this.id });
        },

        /**
         * 刪除列
         * 
         * 說明：觸發刪除列的事件
         *
         * 參數：無
         * 
         * 回傳：無
         *
         * 流程：
         * 1. 觸發 'row_event_click' 事件，並傳遞 'delete_row' 和列的 ID
         */
        delete_row: function () {
            // 觸發 'row_event_click' 事件，並傳遞 'delete_row' 和列的 ID
            this.$emit('row_event_click', 'delete_row', { 'id': this.id });
        },

        /**
         * 擴展列
         * 
         * 說明：根據按鈕的元數據擴展列
         *
         * 參數：
         * @param {Object} button_meta 按鈕的元數據
         * 
         * 回傳：無
         *
         * 流程：
         * 1. 初始化按鈕連結元數據
         * 2. 設定按鈕連結元數據的 widget_code 和參數
         * 3. 如果有擴展參數，則加入到按鈕連結元數據中
         * 4. 如果有擴展標題，則更新列的標題
         * 5. 設定麵包屑的標題
         * 6. 觸發 'row_event_click' 事件，並傳遞 'extend_row' 和按鈕連結元數據
         */
        extend_row: function (button_meta) {
            // 初始化按鈕連結元數據
            let button_link_meta = {};

            // 設定按鈕連結元數據的 widget_code 和參數
            button_link_meta.widget_code = button_meta.widget;
            button_link_meta[button_meta.param] = this.id;

            // 如果有擴展參數，則加入到按鈕連結元數據中
            if (button_meta.extend_param != undefined) {
                for (let key in button_meta.extend_param) {
                    button_link_meta[key] = button_meta.extend_param[key];
                }
            }

            // 如果有擴展標題，則更新列的標題
            if (button_meta.extend_title != undefined) {
                this.value['title'] = this.value['title'] + '(' + button_meta.extend_title + ')';
            }

            // 設定麵包屑的標題
            button_link_meta['breadcrumb_title'] = this.value['title'];

            // 觸發 'row_event_click' 事件，並傳遞 'extend_row' 和按鈕連結元數據
            this.$emit('row_event_click', 'extend_row', button_link_meta);
        },

        /**
         * 輸入事件
         * 
         * 說明：處理輸入事件，並根據輸入的值更新資料
         *
         * 參數：
         * @param {Object} meta 輸入的元數據
         * @param {Event} $event 輸入事件
         * 
         * 回傳：無
         *
         * 流程：
         * 1. 如果事件類型是 'button_event'，則調用 extend_row 方法
         * 2. 更新對應欄位的值
         * 3. 初始化參數物件
         * 4. 如果有資料領域的設定，則加入到參數中
         * 5. 將參數物件轉換成網址字串
         * 6. 組合 API 網址
         * 7. 發送資料到 API
         * 8. 處理 API 回應，顯示錯誤訊息或更新表單
         */
        input_event(meta, $event) {
            // 如果事件類型是 'button_event'，則調用 extend_row 方法
            if ($event.type != undefined && $event.type == 'button_event') {
                this.extend_row($event);
                return;
            }

            // 保存當前上下文
            let $this = this;

            // 更新對應欄位的值
            this.value[meta.variable] = ($event.temp == undefined) ? $event.target.value : $event.temp;

            // 初始化參數物件
            let params = {};

            // 如果有資料領域的設定，則加入到參數中
            if (this.widget_properties.datazone_setting != undefined &&
                this.widget_properties.datazone_setting.datazone != undefined &&
                this.widget_properties.datazone_setting.datazone != '') {
                params['dz'] = this.widget_properties.datazone_setting.datazone;
            }

            // 初始化參數的網址字串
            let parameters = '';
            // 將參數物件轉換成網址字串
            for (let pkey in params) {
                if (parameters != '') {
                    parameters += '&';
                }
                parameters += `${pkey}=${params[pkey]}`;
            }

            // 如果有參數，則加上 '?' 字元
            if (parameters != '') {
                parameters = '?' + parameters;
            }

            // 組合 API 網址
            let api_url = this.$microlink.base_api_url + this.widget_properties.events.form_save + parameters;

            // 發送資料到 API
            this.$microlink.api_post(api_url, this.value, true, function (response) {
                // 如果回應狀態是 'failed'
                if (response.status == 'failed') {
                    // 如果是欄位驗證錯誤
                    if (response.type == 'field-validate') {
                        // 將錯誤訊息顯示在表單上
                        for (var key in response.data) {
                            $this.message[key] = response.data[key];
                        }
                        // 顯示錯誤訊息
                        $this.$microlink.alert('儲存失敗', '請檢查欄位資料是否正確', 'error');

                    }
                    else {
                        // 顯示一般錯誤訊息
                        $this.$microlink.alert('儲存失敗', response.message, 'error');
                    }

                }
            });
        }
    }
};
</script>

<style scoped></style>