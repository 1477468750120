<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th style="width: 10px">
          <input type="checkbox" />
        </th>
        <th class="operate">指令</th>
        <template v-for="field in list_properties.list_fields" v-bind:key="field.label">
          <th>{{ field.label }}</th>
        </template>
      </tr>
    </thead>
    <tbody>
      <template v-for="(row, index) in value" v-bind:key="index">
        <ml-row v-bind:data="row" v-bind:properties="list_properties" v-bind:id="index"
          @row_event_click="row_event"></ml-row>
      </template>
    </tbody>
  </table>
</template>

<script>
import Row from "./Row.vue"
export default {
  components: {
    'ml-row': Row
  },
  props: ['properties', 'rows'],
  data() {
    return {
      list_properties: this.properties,
      value: this.rows
    }
  },
  methods: {
    row_event: function (event, params) {
      this.$emit('row_event', event, params);
    }
  }
};
</script>

<style scoped>
.operate {
  width: auto;
}


</style>